<template>
    <div id="lazy-load-block">
        <div v-if="products.length" class="mt-10">
            <h3 class="font-heading md:text-xl mb-5">Переглянуті товари</h3>

            <ProductsHorizontalList :products="products"/>
        </div>
    </div>
</template>

<script>
import ProductsHorizontalList from "@/Pages/Partials/ProductsHorizontalList.vue";
import axios from "axios";

export default {
    components: {ProductsHorizontalList},
    data() {
        return {
            products: []
        };
    },
    mounted() {
        const target = document.querySelector('#lazy-load-block');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    axios.get(this.route('viewed_products'))
                        .then((response) => this.products = response.data)

                    observer.unobserve(entry.target);
                }
            });
        });
        observer.observe(target);
    }
}
</script>
